<script>
export default {
  name: "FooterForm2",
  mounted() {
    // Load external JavaScript when component is mounted
    // import('utils/form.js').then((formScript) => {
    //   formScript.initForm(this.$el);
    // });
  },
};
</script>

<template>
  <form
    id="se-webform-5"
    method="post"
    target="_self"
    class=""
    action="https://app.smartemailing.cz/public/web-forms-v2/display-form/668133-ir8iu2pf5l4z3mahsg7telul0o7ynw5ww2k6fyv7e5shoeca6eoo93lj9chl1gm4hutqpzpp3gdtz5ujigqrntq9jr56b5gp8q28"
  >
    <div class="se-webform-inner w-full max-w-xl p-6">
      <div class="se-body w-full">
        <ul>
          <li>
            <input
              id="frm-webFormHtmlRenderer-webFormForm-fields-df_name"
              class="footer-input block !bg-transparent !placeholder-white mb-4 py-2 pl-9 pr-3 !shadow-sm focus:outline-none focus:ring-transparent focus:ring-1 sm:text-sm"
              type="text"
              name="fields[df_name]"
              required
              :placeholder="$t('footer.newsletter.name')"
              data-nette-rules='[{"op":":filled","msg":"Křestní jméno je povinné pole"}]'
            />
          </li>
          <li>
            <input
              id="frm-webFormHtmlRenderer-webFormForm-fields-df_surname"
              class="footer-input block bg-transparent !placeholder-white mb-4 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:ring-transparent focus:ring-1 sm:text-sm"
              :placeholder="$t('footer.newsletter.surname')"
              type="text"
              name="fields[df_surname]"
            />
          </li>
          <li>
            <input
              id="frm-webFormHtmlRenderer-webFormForm-fields-df_emailaddress"
              :placeholder="$t('footer.newsletter.email')"
              class="footer-input block bg-transparent placeholder-white mb-4 py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:ring-transparent focus:ring-1 sm:text-sm"
              type="text"
              name="fields[df_emailaddress]"
              data-emailaddress="1"
              required
              data-nette-rules='[{"op":":filled","msg":"E-mailová adresa je povinné pole."},{"op":":email","msg":"E-mailová adresa je povinné pole."},{"op":"SE20\\Validators\\TypesFormValidator::validateEmailAddress","msg":"E-mailová adresa je povinné pole."}]'
            />
          </li>

          <div class="flex justify-center px-12 gdpr">
            <li class="text-white">
              <span
                >{{ $t("footer.newsletter.gdpr.part1") }}
                <a
                  :href="
                    $i18n.locale === 'cs'
                      ? '../Zásadyzpracováníosobníchúdajů.pdf'
                      : '../Opero-Privacy-Policy.pdf'
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline hover:no-underline"
                  >{{ $t("footer.newsletter.gdpr.part2") }}</a
                ></span
              >
            </li>
          </div>
        </ul>
      </div>

      <div class="flex justify-center w-full">
        <button
          class="bg-transparent border rounded-full border-white text-white mt-4 mb-4 px-4 py-2"
          type="submit"
          name="_submit"
          :value="$t('footer.newsletter.submit')"
        >
          {{ $t("footer.newsletter.submit") }}
        </button>
      </div>
    </div>

    <input id="se-ref-field-id" type="hidden" name="referrer" value="" />
    <input id="se-sessionid-field" type="hidden" name="sessionid" value="" />
    <input id="se-sessionUid-field" type="hidden" name="sessionUid" value="" />
    <input
      type="hidden"
      name="_do"
      value="webFormHtmlRenderer-webFormForm-submit"
    />
  </form>
</template>

<style scoped lang="scss">
#se-webform-5.se-webform {
  background: unset;
}

ul {
  max-width: 590px;
}

.footer-input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  width: 540px;

  @media screen and (max-width: 768px) {
    //width: 450px;
    width: 100%;
    //margin: 0px 12px;
  }
  //@media screen and (max-width: 530px) {
  //  width: 350px;
  //}
  //@media screen and (max-width: 430px) {
  //  width: 100%;
  //}
}

button:hover {
  background-color: white;
  color: black;
}

.gdpr {
  font-size: 14px;
  text-align: center;
  font-weight: 300;
}
</style>
