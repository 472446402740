<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="ft-container flex justify-center"
    @mouseenter="
      setCursor(null, scrollTo, {
        'font-size': '14px',
        width: '25px',
        height: '25px',
        'z-index': '1',
        background: '#FFC800',
      })
    "
  >
    <div class="max-w-7xl z-10 relative">
      <h2 class="h1 px-6 py-12 lg:p-24 lg:pb-12 text-center">
        {{ $t("homepage.footer.title") }}
      </h2>
      <div class="text-center mb-8 px-6 md:px-0">
        {{ $t("homepage.footer.subtitle.part1") }}<br />
        {{ $t("homepage.footer.subtitle.part2") }}
      </div>
      <div class="flex justify-center">
        <FooterForm2 />
      </div>
      <div class="flex justify-center"></div>
      <div
        class="grid lg:grid-cols-6 lg:grid-rows-2 justify-center gap-12 p-12 z-10 relative"
        @mouseenter="
          setCursor(null, null, {
            'font-size': '14px',
            background: '#FFC800',
            width: '25px',
            height: '25px',
            'z-index': '1',
          })
        "
      >
        <div class="mr-12 col-span-2 flex justify-center">
          <img src="/img/logo-opero.svg" class="logo-footer" />
        </div>
        <div>
          <div>
            <NuxtLinkLocale to="about">{{ $t("aboutUs") }}</NuxtLinkLocale>
          </div>
          <div class="mt-2">
            <NuxtLinkLocale to="spaces">{{ $t("space") }}</NuxtLinkLocale>
          </div>
          <div class="mt-2">
            <NuxtLinkLocale to="membership">{{
              $t("membership")
            }}</NuxtLinkLocale>
          </div>
          <div class="mt-2">
            <NuxtLinkLocale to="projects">{{ $t("projects") }}</NuxtLinkLocale>
          </div>
        </div>

        <div>
          <div>
            <NuxtLinkLocale to="articles">{{ $t("Blog") }}</NuxtLinkLocale>
          </div>
          <div class="mt-2">
            <NuxtLinkLocale to="events">{{ $t("events") }}</NuxtLinkLocale>
          </div>
          <div class="mt-2">
            <NuxtLinkLocale to="contact">{{ $t("contact") }}</NuxtLinkLocale>
          </div>
          <div class="mt-2">
            <a
              href="https://membersonly.opero.cz/login"
              target="_blank"
              rel="noopener noreferrer"
              >{{ $t("entryForMembers") }}
            </a>
          </div>
        </div>
        <div class="col-span-2 flex flex-col">
          <div class="font-bold">{{ $t("homepage.footer.becomeMember") }}</div>
          <div>
            <a
              target="_blank"
              href="https://www.cocuma.cz/company/opero/"
              rel="noopener noreferrer"
            >
              <button
                class="bg-transparent border rounded-full border-white text-white px-4 py-2 mt-6 font-light"
              >
                {{ $t("homepage.footer.openPosition") }}
              </button>
            </a>
          </div>
        </div>

        <div class="col-span-2"></div>
        <div class="mt-0">
          <div>Opero s.r.o.</div>
          <div class="mt-1">Salvátorská 931/8</div>
          <div class="mt-1">110 00, Praha 1</div>
        </div>
        <div class="col-span-2 md:col-span-1">
          <div>{{ $t("homepage.footer.open") }}:</div>
          <div class="mt-1">{{ $t("homepage.footer.openHours.part1") }}</div>
          <div class="mt-1">{{ $t("homepage.footer.openHours.part2") }}</div>
        </div>
        <div>
          <div class="mt-1">{{ $t("homepage.footer.contact") }}:</div>
          <div class="mt-1">+420 777 167 376</div>
          <div class="mt-1">recepce@opero.cz</div>
        </div>
        <div>
          <div>{{ $t("homepage.footer.followUs") }}</div>
          <div class="flex gap-5 mt-3">
            <a
              target="_blank"
              href="https://www.facebook.com/operoPrague"
              rel="noopener noreferrer"
            >
              <img src="/img/fb.svg" />
            </a>
            <a
              target="_blank"
              href="https://instagram.com/opero_prague?igshid=MzRlODBiNWFlZA=="
            >
              <img src="/img/ig.svg" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/operoprague/"
            >
              <img src="/img/in.svg" />
            </a>
          </div>
        </div>
      </div>

      <div class="flex justify-center mb-8 text-gray-600 px-12 lg:p-0">
        2024 Opero | Všechna práva vyhrazena |
        <a
          class="ml-1"
          target="_blank"
          rel="noopener noreferrer"
          :href="
            $i18n.locale === 'cs'
              ? '../Zásadyzpracováníosobníchúdajů.pdf'
              : '../Opero-Privacy-Policy.pdf'
          "
        >
          {{ $t("footer.privacyPolicy") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    setCursor: {
      type: Function,
    },
  },
  methods: {
    scrollTo() {},
  },
};
</script>

<style scoped lang="scss">
.ft-container {
  background-color: #1a1a1a;
  color: #ffffff;

  button:hover {
    background-color: white;
    color: black;
  }

  a {
    font-weight: 400;
  }
}
.logo-footer {
  height: 60px;
  width: 180px;
}

.footer-input {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  width: 590px;

  @media screen and (max-width: 768px) {
    width: 450px;
  }
  @media screen and (max-width: 530px) {
    width: 350px;
  }
  @media screen and (max-width: 430px) {
    width: 100%;
  }
}
</style>
